<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          :dark-mode="true" />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Bem vindo a Minha Star
                  </h3>
                  <p class="mb-0">Entre com seu Login e senha</p>
                </div>
                <div class="card-body">
                  <form role="Login" @submit="(e) => { e.preventDefault() }" class="text-start">

                    <div class="mb-1">
                      <label>Usuario</label>
                      <input class="form-control" id="login" type="text" v-model="login.Login" placeholder="Usuario"
                        aria-label="Usuario" />
                    </div>
                    <div class="mb-3">
                      <label>Senha</label>
                      <input class="form-control" id="password" type="password" v-model="login.Senha" placeholder="Senha"
                        aria-label="Password" />
                    </div>
                    <soft-switch id="rememberMe" name="rememberMe" checked>
                      Lembrar
                    </soft-switch>
                    <div class="text-center">
                      <soft-button class="my-4 mb-2" variant="gradient" color="success" full-width @click="Login">ENTRAR
                      </soft-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Clique
                    <router-link :to="{ name: 'LoginAdm' }"
                      class="text-success text-gradient font-weight-bold">aqui</router-link>
                    para entrar como administrador
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/img-minhastar/empresario-de-baixo-angulo.jpg') +
                    ')',
                }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import { useAuthStore } from "../../stores/auth";
let AuthStore;

export default {
  name: "LoginPremium",
  components: {
    Navbar,
    AppFooter,
    SoftSwitch,
    SoftButton,
  },
  data() {
    return {
      AuthStore,
      login: {
        Login: "",
        Senha: "",
      },
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async Login() {
      if (!this.login.Login || !this.login.Senha) {
        this.$notify({ type: "error", text: "Os dados informados são inválidos.", duration: 3000 });
      } else if (this.login.Senha.length < 6) {
        this.$notify({ type: "error", text: "A senha deve ter 6 ou mais caracteres.", duration: 3000 });
      } else {
        try {
          var resposta = await AuthStore.doLogin(this.login).catch(() => {
            this.$notify({ type: "error", text: "Algo deu errado! Tente novamente.", duration: 3000, });
          });
          if (resposta) {
            this.$notify({ type: "error", text: resposta, duration: 3000 });
          } else {
            const toPath = this.$route.query.to || "/dashboard";
            this.$router.push(toPath);
          }
        } catch (err) {
          if (err.response.data.detail) {
            this.$notify({ type: "error", text: "Algo deu errado! Tente novamente." });
          }
        }
      }
    }
  },
  setup() {
    AuthStore = useAuthStore();
  }
};
</script>
