<template>
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mx-auto mt-2 mb-4 text-center col-lg-8">
          <a
            href="https://www.facebook.com/STARInterneteTelefonia"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-facebook"></span>
          </a>
          <a
            href="https://instagram.com/star.internet.telefonia"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-instagram"></span>
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=555127472747"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-whatsapp"></span>
          </a>
          <a
            href="https://star.psi.br"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fas fa-globe"></span>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            Copyright © {{ new Date().getFullYear() }} STAR Internet e Telefonia.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer"
};
</script>
